const mobileMenuData = [
  {
    id: 1,
    label: "Accueil",
    icon : "/images/icons/home.svg",
    iconActive : "/images/icons/home-actif.svg",
    routePath: "/"
  },
  {
    id: 2,
    label: "Formation et Sensibilisation",
    items: [
      { name: "Nos formations", routePath: "/nos-formations" },
      { name: "Nos sessions inter-entreprises", routePath: "/sessions" },
      { name: "Nos solutions de sensibilisation", routePath: "/solutions-sensibilisation" },
      { name: "Dispositifs de financement", routePath: "/dispositifs-financement" },
    ],
  },
  {
    id: 3,
    label: "Pourquoi nous choisir",
    items: [
      { name: "Qui sommes-nous ?", routePath: "/pourquoi-nous-choisir/qui-sommes-nous" },
      { name: "Nos formations, pourquoi et pour qui ?", routePath: "/pourquoi-nous-choisir/formation-pourquoi-pour-qui" },
      { name: "Méthodes et déroulement", routePath: "/pourquoi-nous-choisir/methodes-deroulement" },
      { name: "Certification Qualiopi", routePath: "/pourquoi-nous-choisir/certification-qualiopi" },
      { name: "Technologie et accessibilité", routePath: "/pourquoi-nous-choisir/technologie-accessibilite" },
    ],
  },
  {
    id: 4,
    label: "FAQ",
    routePath: "/resources/faq"
  },
  {
    id: 5,
    label: "Contact",
    routePath: "/contact"
  },
];

export default mobileMenuData;
