import React from 'react';
import Parser from 'html-react-parser';

function Sidebar1({title, description,button, url, index, background, image, imageAlt}) {
    const bgStyle = background ? { background } : {};
    return (
         <div className="sidebar-widget sidebar1" style={bgStyle} key={index}>
            <h4 className="widget-title">{title}</h4>
            <div className="widget-content">
                <div className="map-outer mb-0">
                    {image && (
                        <img
                            className="border-arrondi mb-2"
                            src={image}
                            alt={imageAlt ? ({imageAlt}) : 'image' }
                        />
                    )}
                    {description && (<p>{Parser(description)}</p>)}
                    {button && (
                        <a href={url}
                           className="theme-btn btn-style-six small mt-3"
                           style={{margin: 'auto', display: 'block', width: 'fit-content'}}>
                            {button}
                        </a>
                    )}
                </div>
            </div>
         </div>

    );
}

export default Sidebar1;
